import axiosRetry from 'axios-retry';

import { MirrorService } from 'services/MirrorService';

import { TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY } from 'helpers/constants';
import { getScreenOrientationWithSizes } from 'helpers/device';
import { getProfilesListInfo } from 'helpers/profiles';
import { getSessionStorageItem } from 'helpers/sessionStorage';

import { httpClient } from './httpClient';

interface ScreenParameters {
  screen: {
    orientation: 'landscape' | 'portrait';
    width: number;
    height: number;
  };
}

const getBuildVersionFromSessionStorage = () => {
  return (
    getSessionStorageItem(TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY) || '0'
  );
};

export const TrackingApi = {
  trackError(payload: { message: string; params?: any }) {
    return httpClient.post('/log/error', {
      message: payload.message,
      params: {
        ...payload.params,
        buildId: getBuildVersionFromSessionStorage(),
        front_user_agent: navigator?.userAgent,
      },
    });
  },

  trackWarning(payload: { message: string; params?: any }) {
    return httpClient.post('/log/warning', {
      message: payload.message,
      params: {
        ...payload.params,
        buildId: getBuildVersionFromSessionStorage(),
        front_user_agent: navigator?.userAgent,
      },
    });
  },

  fetchInitialId(traceId?: string) {
    axiosRetry(httpClient, {
      retries: 3,
      retryDelay: () => 100,
    });

    const origin = MirrorService.originForInitialId;

    return httpClient.get<{ initial_id: string }>(`${origin}/get-initial-id`, {
      params: {
        trace_id: traceId,
      },
    });
  },

  trackPageLoad(payload: {
    url: string;
    currentUserId: string;
    interactionWithUserId?: string;
  }) {
    const { orientation, width, height } = getScreenOrientationWithSizes();

    return httpClient.post(
      '/track/events/page-load',
      {
        url: payload.url,
        pageLoadId: window.pageLoadId,
        screenOrientation: orientation,
        screenHeight: height,
        screenWidth: width,
        source: getProfilesListInfo() ? 'extension' : 'chatshouse',
        ...(!!payload.interactionWithUserId && {
          interactionWithUserId: payload.interactionWithUserId || '',
        }),
      },
      {
        headers: {
          ...(!!payload.currentUserId && {
            'X-ULID': payload.currentUserId || '',
          }),
        },
      }
    );
  },

  trackScreenOrientation(payload: ScreenParameters) {
    return httpClient.post('/track/screen-rotate', payload);
  },

  trackMessageReceived(payload: { messageId: number; contactId: string }) {
    return httpClient.get(
      `/track/message-received/${payload.messageId}/${payload.contactId}`
    );
  },

  trackRequestReceived(payload: { requestId: number }) {
    return httpClient.get(
      `/track/chat-request-message-received/${payload.requestId}`
    );
  },

  trackMessageSent(payload: { messageId: number; contactId: string }) {
    return httpClient.get(
      `/track/message-sent/${payload.messageId}/${payload.contactId}`
    );
  },

  trackRequestSent(payload: { requestId: number }) {
    return httpClient.get(
      `/track/chat-request-message-sent/${payload.requestId}`
    );
  },
};
