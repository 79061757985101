import React, { ReactNode } from 'react';

import { TrackingApi } from 'api/TrackingApi';

import { UnhandledError } from 'components/shared/UnhandledError';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

const ERRORS_LIST_TO_IGNORE = [
  'Loading chunk', // ? User Network or Deploy issues
  'Loading CSS chunk', // ? User Network or Deploy issues
  'Network Error', // ? User Network issues
  //   'The object can not be found here', // ? User js issue
  //   'The object is in an invalid state.', // ? User js issue
  //   "Can't find variable: IntersectionObserver", // ? User js issue - NO SUPPORT
  //   "Can't find variable: ResizeObserver", // ?  User js issue - NO SUPPORT
  //   'IntersectionObserver is not defined', // ?  User js issue - NO SUPPORT
  //   "'getItem' of null", // ? User permission for local storage ( can be disabled from browser settings ). Mostly in iframe with incognito
  //   "(reading 'getItem')", // ? User permission for local storage ( can be disabled from browser settings ). Mostly in iframe with incognito
  //   'No Websocket support and no SockJS configured, can not connect', // ? user has no support for webSockets on his device
  //   // !  NEED RESEARCH
  //   'performance.mark is not a function', // ? performance.mark is not a function. (In 'performance.mark(P.v.LoadStart)', 'performance.mark' is undefined)
];

const shouldErrorBeTracked = (errorMessage?: any) => {
  if (!errorMessage) return true;

  return !ERRORS_LIST_TO_IGNORE.find(
    (errorToIgnore) => errorMessage?.includes(errorToIgnore)
  );
};

export class GlobalErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    if (
      shouldErrorBeTracked(error?.message) &&
      process.env.NODE_ENV !== 'development'
    ) {
      TrackingApi.trackError({
        message: `GlobalErrorBoundary: ${error?.message}`,
        params: { stack: error?.stack },
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <UnhandledError />;
    }

    return children;
  }
}
