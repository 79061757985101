import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

type Payload = ({
  currentUrl,
  prevUrl,
}: {
  currentUrl: string;
  prevUrl: string;
}) => void;

const buildFullUrl = (pathname: string, search: string) => {
  return `${document.location.origin}${pathname}${search}`;
};

export const useLocationChange = (cb: Payload) => {
  const { pathname, search } = useLocation();

  const prevUrl = useRef(document.referrer);

  useEffect(() => {
    const currentUrl = buildFullUrl(pathname, search);

    cb({
      currentUrl,
      prevUrl: prevUrl.current,
    });

    prevUrl.current = currentUrl;
  }, [cb, pathname, search]);
};
