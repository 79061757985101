import { useCallback, useRef } from 'react';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';

import { MirrorService } from 'services/MirrorService';

import { TrackingApi } from 'api/TrackingApi';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';

interface MissingKeyPayload {
  message: string;
  params: Record<string, string>;
}

const TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY =
  'TRANSLATIONS_BUILD_VERSION';

const getLoadUrl = (languages: string[], namespaces: string[]) => {
  // const lng = languages[0];
  const lng = 'en';
  const ns = namespaces[0];

  return `${MirrorService.originForTranslations}/translations/frontend/${lng}/${ns}.json`;
};
const getNamespaceFromUrl = (url: string) => {
  const match = url.match(/([a-zA-Z]+)\.json/);

  return match ? match[1] : '';
};

const setBuildVersionToSessionStorage = (version: string) => {
  setSessionStorageItem(
    TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY,
    version
  );
};

const getBuildVersionFromSessionStorage = () =>
  getSessionStorageItem(TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY) || '0';

export const useI18n = () => {
  const namespacesLoadFail = useRef<Record<string, boolean>>({});
  const namespacesLoadSuccess = useRef<Record<string, any>>({});

  const initI18n = useCallback(() => {
    // const lng = cookie.get('language') || 'en';
    const lng = 'en';
    const version = getBuildVersionFromSessionStorage();
    const isProduction = process.env.NODE_ENV === 'production';

    return i18n
      .use(initReactI18next)
      .use(HttpApi)
      .init({
        maxRetries: 0,
        debug: false,
        lng,
        fallbackLng: 'en',
        ns: ['common', 'app'],
        defaultNS: 'common',
        saveMissing: isProduction,
        missingKeyNoValueFallbackToKey: true,
        partialBundledLanguages: true,
        interpolation: { escapeValue: false },
        backend: {
          crossDomain: true,
          withCredentials: false,
          loadPath: getLoadUrl,
          queryStringParams: { v: version },
          parsePayload: (namespace, key): MissingKeyPayload => {
            return {
              message: `Translation key ${key} missing`,
              params: {
                namespace,
                key,
                lng,
                buildId: String(version),
                response: namespacesLoadSuccess.current[namespace],
                userAgent: navigator.userAgent,
              },
            };
          },
          request: async (
            options,
            url,
            payload: MissingKeyPayload,
            callback
          ) => {
            if (!payload) {
              const namespace = getNamespaceFromUrl(url);
              try {
                const response = await axios.get(url, {
                  withCredentials: false,
                  params: options.queryStringParams,
                });

                if (!response.data) {
                  TrackingApi.trackWarning({
                    message: 'Translation: empty response data',
                    params: {
                      status: response.status,
                    },
                  });
                }

                namespacesLoadSuccess.current[namespace] = {
                  status: response.status,
                  data: response.data,
                };
                callback(null, response);
              } catch (err: any) {
                namespacesLoadFail.current[namespace] = true;

                callback(err, { status: err.status, data: '' });
              }
            } else if (
              !namespacesLoadFail.current?.[payload.params?.namespace]
            ) {
              TrackingApi.trackError({
                message: payload.message,
                params: payload.params,
              });
            }
          },
        },
      });
  }, []);

  return {
    i18n,
    initI18n,
    setBuildVersionToSessionStorage,
    getBuildVersionFromSessionStorage,
  };
};
