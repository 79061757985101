import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NewActivity } from 'types';
import { UserCard } from 'types/interfaces/user/UserCard';

import { DEFAULT_FINDER_FILTERS, FinderFilters } from 'api/FinderApi';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';

export const FINDER_FILTERS = 'finderFilters';

const initFinderFilters = () => {
  try {
    const filtersFromSessionStorage = getSessionStorageItem(FINDER_FILTERS);

    if (!filtersFromSessionStorage) {
      return DEFAULT_FINDER_FILTERS;
    }

    return JSON.parse(filtersFromSessionStorage);
  } catch (error) {
    return DEFAULT_FINDER_FILTERS;
  }
};

type FinderState = {
  users: UserCard[];
  filters: FinderFilters;
  finderPage: number | null;
  sympathies: NewActivity | null;
  loading: boolean;
  initLoading: boolean;
};

const initialState: FinderState = {
  users: [],
  filters: initFinderFilters(),
  finderPage: 1,
  sympathies: null,
  loading: false,
  initLoading: true,
};

const finderSlice = createSlice({
  name: 'finder',
  initialState,
  reducers: {
    addUsers(state, action: PayloadAction<UserCard[]>) {
      state.users = [...state.users, ...action.payload];
    },

    setUsers(state, action: PayloadAction<UserCard[]>) {
      state.users = action.payload;
    },

    likeUser(
      state,
      action: PayloadAction<{ userId: string; isLiked: boolean }>
    ) {
      const { userId, isLiked } = action.payload;

      state.users = state.users.map((user) =>
        user.ulid_id === userId ? { ...user, liked: isLiked } : user
      );
    },

    setFilters(state, action: PayloadAction<FinderFilters>) {
      state.filters = action.payload;

      setSessionStorageItem(FINDER_FILTERS, JSON.stringify(action.payload));
    },

    setNextFinderPage(state, action: PayloadAction<number | null>) {
      state.finderPage = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setInitLoading(state, action: PayloadAction<boolean>) {
      state.initLoading = action.payload;
    },

    setSympathies(state, action: PayloadAction<NewActivity>) {
      state.sympathies = action.payload;
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  addUsers,
  setUsers,
  likeUser,
  setFilters,
  setNextFinderPage,
  setLoading,
  setInitLoading,
  setSympathies,

  resetState,
} = finderSlice.actions;

export const finder = finderSlice.reducer;
