import { combineReducers } from '@reduxjs/toolkit';

import { auth } from 'store/auth/authSlice';
import { common } from 'store/common/commonSlice';
import { finder } from 'store/finder/finderSlice';
import { icebreakers } from 'store/icebreakers/icebreakersSlice';
import { inbox } from 'store/mails/mailsSlice';
import { messenger } from 'store/messenger/messengerSlice';
import { notifications } from 'store/notifications/notificationsSlice';
import { profile } from 'store/profile/profileSlice';
import { systemPopup } from 'store/systemPopup/systemPopupSlice';

export const rootReducer = combineReducers({
  auth,
  common,
  finder,
  icebreakers,
  inbox,
  messenger,
  notifications,
  profile,
  systemPopup,
});

export type RootState = ReturnType<typeof rootReducer>;
