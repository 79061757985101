import {
  INotification,
  NotificationInfoByType,
} from 'types/interfaces/Notifications';

import { httpClient } from './httpClient';

export interface NotificationsResponse {
  unreads: {
    visits: NotificationInfoByType;
    likes: NotificationInfoByType;
    matches: NotificationInfoByType;
  };
  notifications: INotification[];
}

export const NotificationsApi = {
  async fetchNotifications() {
    const { data } = await httpClient.get<NotificationsResponse>(
      '/notifications',
      {
        headers: {
          'X-ULID': null,
        },
      }
    );

    return data;
  },
  async readNotification({ notificationId }: { notificationId: number }) {
    const { data } = await httpClient.post(
      `/notifications/${notificationId}/read`
    );

    return data;
  },
};
