import { AppDomain } from 'types/enums/AppDomain';

interface SiteConfig {
  id: number;
  groupId: number;
  domain: AppDomain;
}

export const SITES_CONFIGS: Record<string, SiteConfig> = {
  ChatsHouse: {
    id: 4,
    groupId: 2,
    domain: AppDomain.ChatsHouse,
  },
};

export const DEFAULT_SITE_ID = SITES_CONFIGS.ChatsHouse.id;
export const DEFAULT_DEV_SITE_DOMAIN = 'spiddy.co';
export const DEFAULT_PRODUCTION_SITE_DOMAIN = SITES_CONFIGS.ChatsHouse.domain;
