import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import { Logo } from '../Logo';

import css from './unhandledError.module.sass';

export const UnhandledError = () => {
  const { t } = useTranslation('app');

  const handleTryAgainClick = () => {
    window.location.reload();
  };

  return (
    <div className={css.wrapper}>
      <div className={css.logoWrapper}>
        <Logo dark />
      </div>
      <main className={css.main}>
        <div className={css.content}>
          <h1 className={css.title}>500</h1>
          <p className={css.text}>{t('internalError.title')}</p>

          <BaseButton
            className={css.submitBtn}
            type={ButtonTypes.Accent}
            onClick={handleTryAgainClick}
          >
            {t('internalError.submitBtn')}
          </BaseButton>
        </div>
      </main>
    </div>
  );
};
