import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { MirrorService } from 'services/MirrorService';

import { isTermsOrPolicyPage } from 'helpers/route';
import { firstToUppercase } from 'helpers/utils/firstToUppercase';

const setSiteTitle = () => {
  document.title = firstToUppercase(MirrorService.siteName);
};

const getFaviconFileName = (size: '16' | '32') => {
  const domainName = MirrorService.domain.split('.')[0];

  return `${domainName}-${size}x${size}.png`;
};

const createFaviconElement = (
  id: string,
  fileName: string,
  size: '16' | '32'
) => {
  const element = document.createElement('link');

  element.id = id;
  element.setAttribute('rel', 'icon');
  element.setAttribute('type', 'image/png');
  element.setAttribute('sizes', `${size}x${size}`);
  element.setAttribute('href', `/favicons/${fileName}`);

  return element;
};

const setSiteFavicons = () => {
  const favicon16Id = 'fav16';
  const favicon32Id = 'fav32';
  const favicon16FileName = getFaviconFileName('16');
  const favicon32FileName = getFaviconFileName('32');
  const existingFavicon16Element = document.getElementById(favicon16Id);
  const existingFavicon32Element = document.getElementById(favicon32Id);

  if (existingFavicon16Element && existingFavicon32Element) {
    existingFavicon16Element.setAttribute(
      'href',
      `/favicons/${favicon16FileName}`
    );
    existingFavicon32Element.setAttribute(
      'href',
      `/favicons/${favicon32FileName}`
    );
  } else {
    const headElement = document.getElementsByTagName('head')[0];
    const favicon16Element = createFaviconElement(
      favicon16Id,
      favicon16FileName,
      '16'
    );
    const favicon32Element = createFaviconElement(
      favicon32Id,
      favicon32FileName,
      '32'
    );

    headElement.appendChild(favicon16Element);
    headElement.appendChild(favicon32Element);
  }
};

const getManifestFileName = () => {
  const domainName = MirrorService.domain.split('.')[0];

  return `${domainName}.manifest.json`;
};

const setSiteManifest = () => {
  const elementId = 'manifest';
  const manifestFileName = getManifestFileName();
  const existingElement = document.getElementById(elementId);

  if (existingElement) {
    existingElement.setAttribute('href', `/manifests/${manifestFileName}`);
  } else {
    const headElement = document.getElementsByTagName('head')[0];
    const manifestElement = document.createElement('link');

    manifestElement.id = 'manifest';
    manifestElement.setAttribute('rel', 'manifest');
    manifestElement.setAttribute('href', `/manifests/${manifestFileName}`);
    headElement.appendChild(manifestElement);
  }
};

const addNoindexMetaTag = () => {
  const node = document.createElement('meta');
  node.id = 'noindex';
  node.name = 'robots';
  node.content = 'noindex';
  document.head.appendChild(node);
};

const removeNoindexMetaTag = () => {
  const node = document.getElementById('noindex');
  if (node) {
    node.remove();
  }
};

export const useAppMeta = () => {
  const location = useLocation();

  useEffect(() => {
    setSiteTitle();
    setSiteFavicons();
    setSiteManifest();
  }, []);

  useEffect(() => {
    if (isTermsOrPolicyPage(location.pathname)) {
      addNoindexMetaTag();
    }

    return () => {
      removeNoindexMetaTag();
    };
  }, [location]);
};
