import { MirrorService } from 'services/MirrorService';

import { capitalizedFirstLetter } from './string';

interface BadgeOptions {
  backgroundColor?: string;
  color?: string;
  size?: number;
  position?: string; // Position inside favicon "n", "e", "s", "w", "ne", "nw", "se", "sw"
  radius?: number;
  onChange?: () => void;
}

class Badge {
  private backgroundColor!: string;

  private color!: string;

  private size!: number;

  private position!: string;

  private radius!: number;

  private src?: string | null;

  private onChange!: () => void;

  private canvas!: HTMLCanvasElement;

  private ctx: CanvasRenderingContext2D | null;

  private img!: HTMLImageElement;

  private faviconEl!: HTMLLinkElement | null;

  private faviconSize!: number;

  private badgeSize!: number;

  private offset!: { x: number; y: number };

  private titleEl: HTMLElement | null;

  private title?: string;

  value!: number;

  constructor(options?: BadgeOptions) {
    Object.assign(
      this,
      {
        backgroundColor: '#f00',
        color: '#fff',
        size: 0.6,
        position: 'ne',
        radius: 8,
        onChange() {},
      },
      options
    );

    this.faviconEl = document.querySelector('link[rel$=icon][sizes="32x32"]');
    this.titleEl = document.querySelector('title');
    this.title = this.titleEl?.innerHTML;

    this.canvas = document.createElement('canvas');
    this.src = this.faviconEl?.getAttribute('href');
    this.ctx = this.canvas.getContext('2d');
    this.value = 0;
  }

  drawIcon() {
    if (!this?.ctx) return;

    this.ctx.clearRect(0, 0, this.faviconSize, this.faviconSize);
    this.ctx.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
  }

  drawShape() {
    if (!this?.ctx || !this.offset) return;

    const r = this.radius;
    const xa = this.offset.x;
    const ya = this.offset.y;
    const xb = this.offset.x + this.badgeSize;
    const yb = this.offset.y + this.badgeSize;
    this.ctx.beginPath();
    this.ctx.moveTo(xb - r, ya);
    this.ctx.quadraticCurveTo(xb, ya, xb, ya + r);
    this.ctx.lineTo(xb, yb - r);
    this.ctx.quadraticCurveTo(xb, yb, xb - r, yb);
    this.ctx.lineTo(xa + r, yb);
    this.ctx.quadraticCurveTo(xa, yb, xa, yb - r);
    this.ctx.lineTo(xa, ya + r);
    this.ctx.quadraticCurveTo(xa, ya, xa + r, ya);
    this.ctx.fillStyle = this.backgroundColor;
    this.ctx.fill();
    this.ctx.closePath();
  }

  drawVal() {
    if (!this?.ctx) return;

    const margin = (this.badgeSize * 0.18) / 2;
    this.ctx.beginPath();
    this.ctx.textBaseline = 'middle';
    this.ctx.textAlign = 'center';
    this.ctx.font = `bold ${this.badgeSize * 0.82}px Arial`;
    this.ctx.fillStyle = this.color;
    this.ctx.fillText(
      String(this.value),
      this.badgeSize / 2 + (this.offset?.x || 0),
      this.badgeSize / 2 + (this.offset?.y || 0) + margin
    );
    this.ctx.closePath();
  }

  drawFavicon() {
    this?.faviconEl?.setAttribute('href', this.dataURL);
  }

  draw() {
    this.drawIcon();
    if (this.value) this.drawShape();
    if (this.value) this.drawVal();
    this.drawFavicon();
  }

  setup() {
    this.faviconSize = this.img?.naturalWidth;
    this.badgeSize = this.faviconSize * this.size;
    this.canvas.width = this.faviconSize;
    this.canvas.height = this.faviconSize;
    const sd = this.faviconSize - this.badgeSize;
    const sd2 = sd / 2;

    const offsetConfig: { [key: string]: { x: number; y: number } } = {
      n: { x: sd2, y: 0 },
      e: { x: sd, y: sd2 },
      s: { x: sd2, y: sd },
      w: { x: 0, y: sd2 },
      nw: { x: 0, y: 0 },
      ne: { x: sd, y: 0 },
      sw: { x: 0, y: sd },
      se: { x: sd, y: sd },
    };

    this.offset = offsetConfig[this.position];
  }

  get dataURL() {
    return this.canvas.toDataURL();
  }

  updateTitle() {
    if (!this.titleEl) return;

    switch (this.value) {
      case 0:
        this.titleEl.innerText = capitalizedFirstLetter(MirrorService.siteName);
        break;
      case 1:
        this.titleEl.innerText = `1 New Message!😉`;
        break;
      default:
        this.titleEl.innerText = `${this.value} New Messages!😉`;
        break;
    }
  }

  update() {
    this.value = Math.min(99, parseInt(String(this.value), 10));
    if (this.img) {
      this.draw();
      if (this.onChange) this.onChange.call(this);
    } else {
      this.img = new Image();
      this.img.addEventListener('load', () => {
        this.setup();
        this.draw();
        if (this.onChange) this.onChange.call(this);
      });
      this.img.src = this.src || '';
    }

    this.updateTitle();
  }

  updateNotificationCounter(val: number) {
    if (this.value === val) return;

    this.value = 0;
    this.update();

    // blink animation
    setTimeout(() => {
      this.value = val;
      this.update();
    }, 500);
  }

  updateFaviconEl(newFaviconEl: any) {
    this.faviconEl = newFaviconEl;
    this.src = this.faviconEl?.getAttribute('href');
  }
}

export const tabBadge = new Badge();
