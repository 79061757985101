import React from 'react';
import { Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import { Pages } from 'types/enums/Pages';
import { RoutesRestrictionType } from 'types/enums/RoutesRestrictionType';

import { useMedia } from 'hooks/useMedia';

import { AppRoute } from 'components/shared/AppRoute';

const EditProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "EditProfilePage" */ 'pages/EditProfilePage'),
  {
    resolveComponent: (component) => component.EditProfilePage,
  }
);

const Faq = loadable(
  () =>
    import(/* webpackChunkName: "Faq" */ 'components/sections/MyProfile/Faq'),
  {
    resolveComponent: (component) => component.Faq,
  }
);

const SettingsList = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingsList" */ 'components/sections/MyProfile/Settings/SettingsList'
    ),
  {
    resolveComponent: (component) => component.SettingsList,
  }
);

const NotificationSettings = loadable(
  () =>
    import(
      /* webpackChunkName: "NotificationSettings" */ 'components/sections/MyProfile/Settings/Notifications'
    ),
  {
    resolveComponent: (component) => component.Notifications,
  }
);

const ContactUs = loadable(
  () =>
    import(
      /* webpackChunkName: "ContactUs" */ 'components/sections/MyProfile/ContactUs'
    ),
  {
    resolveComponent: (component) => component.ContactUs,
  }
);

const NotFoundPage = loadable(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'pages/NotFoundPage'),
  {
    resolveComponent: (component) => component.NotFoundPage,
  }
);

const ChatPage = loadable(
  () => import(/* webpackChunkName: "ChatPage" */ 'pages/ChatPage'),
  {
    resolveComponent: (component) => component.ChatPage,
  }
);

const IcebreakersPage = loadable(
  () =>
    import(/* webpackChunkName: "IcebreakersPage" */ 'pages/IcebreakersPage'),
  {
    resolveComponent: (component) => component.IcebreakersPage,
  }
);

const LegalPage = loadable(
  () => import(/* webpackChunkName: "LegalPage" */ 'pages/LegalPage'),
  {
    resolveComponent: (component) => component.LegalPage,
  }
);

const UserProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "UserProfilePage" */ 'pages/UserProfilePage'),
  {
    resolveComponent: (component) => component.UserProfilePage,
  }
);

const FinderPage = loadable(
  () => import(/* webpackChunkName: "FinderPage" */ 'pages/FinderPage'),
  {
    resolveComponent: (component) => component.FinderPage,
  }
);

const MessengerPage = loadable(
  () => import(/* webpackChunkName: "MessengerPage" */ 'pages/MessengerPage'),
  {
    resolveComponent: (component) => component.MessengerPage,
  }
);

const PresentsPage = loadable(
  () => import(/* webpackChunkName: "PresentsPage" */ 'pages/PresentsPage'),
  {
    resolveComponent: (component) => component.PresentsPage,
  }
);

const PresentsStatisticPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PresentsStatisticPage" */ 'pages/PresentsStatisticPage'
    ),
  {
    resolveComponent: (component) => component.PresentsStatisticPage,
  }
);

const MailsChainsPagePage = loadable(
  () =>
    import(/* webpackChunkName: "MailsChatsPage" */ 'pages/MailsChainsPage'),
  {
    resolveComponent: (component) => component.MailsChatsPage,
  }
);

const MailsChatPage = loadable(
  () => import(/* webpackChunkName: "MailsChatPage" */ 'pages/MailsChatPage'),
  {
    resolveComponent: (component) => component.MailsChatPage,
  }
);

const MyProfilePage = loadable(
  () => import(/* webpackChunkName: "MyProfilePage" */ 'pages/MyProfilePage'),
  {
    resolveComponent: (component) => component.MyProfilePage,
  }
);

const LoginPage = loadable(
  () => import(/* webpackChunkName: "LoginPage" */ 'pages/LoginPage'),
  {
    resolveComponent: (component) => component.LoginPage,
  }
);

const IndexPage = loadable(
  () => import(/* webpackChunkName: "IndexPage" */ 'pages/IndexPage'),
  {
    resolveComponent: (component) => component.IndexPage,
  }
);

export const Routes = () => {
  const { isMobile } = useMedia();

  return (
    <Switch>
      <AppRoute path={Pages.Login} component={LoginPage} isPublic exact />

      <AppRoute path={Pages.Terms} component={LegalPage} exact />

      <AppRoute
        path={Pages.DisclosuresDisclaimers}
        component={LegalPage}
        exact
      />

      <AppRoute path={Pages.ComplianceStatement} component={LegalPage} exact />

      <AppRoute path={Pages.Policy} component={LegalPage} exact />

      <AppRoute
        path={Pages.TrustedPolicy}
        component={LegalPage}
        exact
        isPrivate
      />

      <AppRoute path={Pages.Home} component={IndexPage} isPublic exact />

      <AppRoute path={Pages.Finder} component={FinderPage} isPrivate exact />

      <AppRoute
        path={Pages.Dialogs}
        component={MessengerPage}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.DialogsTread}
        component={isMobile ? ChatPage : MessengerPage}
        isPrivate
      />

      <AppRoute
        path={Pages.Mails}
        component={MailsChainsPagePage}
        isPrivate
        exact
      />

      <AppRoute path={Pages.MailsTread} component={MailsChatPage} isPrivate />

      <AppRoute
        path={Pages.Presents}
        component={PresentsPage}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.PresentsStatistic}
        restrictedType={RoutesRestrictionType.MobileRestricted}
        component={PresentsStatisticPage}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.MyProfile}
        component={MyProfilePage}
        isPrivate
        exact
      />

      <AppRoute path={Pages.ContactUs} component={ContactUs} isPrivate exact />

      <AppRoute path={Pages.FAQ} component={Faq} isPrivate exact />

      <AppRoute path={Pages.Edit} component={EditProfilePage} isPrivate exact />

      <AppRoute
        path={Pages.Settings}
        component={SettingsList}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.Notifications}
        component={NotificationSettings}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.Icebreakers}
        component={IcebreakersPage}
        restrictedType={RoutesRestrictionType.MobileRestricted}
        isPrivate
      />

      <AppRoute
        path={Pages.UserProfile}
        component={UserProfilePage}
        isPrivate
        exact
      />

      <AppRoute path={Pages.NotFound} component={NotFoundPage} />
    </Switch>
  );
};
