import { useCallback } from 'react';

import { setXPrevUrl } from 'api/httpClient';
import { useLocationChange } from 'hooks/useLocationChange';

export const useXPrevUrl = () => {
  const handleSetXPrevUrl = useCallback(({ prevUrl }: { prevUrl: string }) => {
    setXPrevUrl(prevUrl);
  }, []);

  useLocationChange(handleSetXPrevUrl);
};
