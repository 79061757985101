import cookie from 'js-cookie';
import queryString from 'query-string';

import { MirrorService } from 'services/MirrorService';
import { AppDomain } from 'types/enums/AppDomain';

const TRACKING_PARAMS_COOKIE_NAME = 'tracking_params';
const TRACKING_REFERRER_COOKIE_NAME = 'tracking_referer';
const PARTNER_ID_QUERY_NAME = 'partner_id';
const EXPIRE_TIME_IN_DAYS = 30;

export const setTrackingCookiesFromUrl = () => {
  const {
    referrer,
    location: { hostname, search },
  } = document;

  const { [PARTNER_ID_QUERY_NAME]: partnerId } = queryString.parse(search);

  const currentDomain = MirrorService.domainWithoutSubdomain;
  const fromExternalDomain = (url: string) => !url.includes(hostname);

  if (partnerId) {
    if (currentDomain !== AppDomain.ChatsHouse) {
      cookie.set(TRACKING_PARAMS_COOKIE_NAME, search, {
        expires: EXPIRE_TIME_IN_DAYS,
        domain: `.${currentDomain}`,
      });
    }

    cookie.set(TRACKING_PARAMS_COOKIE_NAME, search, {
      expires: EXPIRE_TIME_IN_DAYS,
      domain: `.${AppDomain.ChatsHouse}`,
    });
  }

  if (referrer && fromExternalDomain(referrer)) {
    cookie.set(TRACKING_REFERRER_COOKIE_NAME, referrer, {
      expires: EXPIRE_TIME_IN_DAYS,
      domain: `.${AppDomain.ChatsHouse}`,
    });
  }
};
