import { MirrorService } from 'services/MirrorService';
import { SelectOption } from 'types';
import { SoundNotification } from 'types/interfaces/Notifications';
import { UnreadCounters } from 'types/interfaces/UnreadCounters';
import { User } from 'types/interfaces/user/User';

import { extractPathnameFromHref } from 'helpers/extractPathnameFromHref';

import { httpClient } from './httpClient';

export interface InitResponse {
  unread_counters?: Record<string, UnreadCounters>;
  sound_notification: SoundNotification | boolean;
  real_gift_requested_counter: number;
  is_real_gift_enabled: boolean;
  is_enabled_icebreakers_chains: boolean;
  site_language: {
    options: string[];
    value: string;
  };
  user: User;
}

export type RegisterPayload = {
  email: string;
  password: string;
  gender: number;
  age: number;
  city?: number;
  city_id?: number;
  preland: {
    id: number;
    screen_number: number;
    history_length: number | null;
    params?: Record<
      string,
      string | number | boolean | SelectOption[] | undefined
    >;
  };
};

export interface AutoRegisterPayload {
  [key: string]: string | (string | null)[] | null;
}

export enum OnlineType {
  RealOnline = 1,
  ForceOnline = 2,
  ForceOffline = 3,
  RealOffline = 4,
}

interface LoginPayload {
  email: string;
  password: string;
  auth_code?: string;
}

export enum PackagesThemeGroups {
  Default = 'default',
  FreeTrial = 'trial-group-2',
}

export type PopularUser = {
  id: string;
  photo_url: string;
};

export const AuthApi = {
  async init({ from }: { from: string }): Promise<InitResponse> {
    const initLoadStart = Date.now();

    const { data } = await httpClient.get<InitResponse>('/init', {
      params: MirrorService.isProd
        ? {
            from: extractPathnameFromHref(from) || 'ref',
            ft: initLoadStart,
          }
        : {},
      headers: {
        'X-ULID': null,
      },
    });

    return data;
  },

  async register(payload: RegisterPayload) {
    const { data } = await httpClient.post<User>('/register', payload);

    return data;
  },

  async autoRegister(payload: AutoRegisterPayload) {
    const { data } = await httpClient.get<User>('/register/auto', {
      params: payload,
    });

    return data;
  },

  async login(payload: LoginPayload) {
    const { data } = await httpClient.post<User>('/login', payload);

    return data;
  },

  async checkEmail({ email }: { email: string }) {
    await httpClient.post('/register/check-email', { email, unique: email });
  },

  async checkCode({ code }: { code: string }) {
    await httpClient.get(`/recovery/check-code/${code}`);
  },

  async sendVerificationCode({ email }: { email: string }) {
    await httpClient.post('/recovery/send-mail', { email });
  },

  async changePassword({ password, code }: { password: string; code: string }) {
    await httpClient.post(`/recovery/change-password/${code}`, { password });
  },

  async getProfilesWithPresents() {
    const { data } = await httpClient.get<{
      data: {
        display_profile_ulid: string;
        is_real_gift_available: boolean;
      }[];
    }>('/real-gift/real-gift-availability');

    return (
      data?.data
        ?.filter(({ is_real_gift_available }) => !!is_real_gift_available)
        ?.map(({ display_profile_ulid }) => display_profile_ulid) || []
    );
  },

  async fetchWSConnectionToken() {
    const { data } = await httpClient.get<{ token: string }>(
      '/broadcasting/token',
      {
        headers: {
          'X-ULID': null,
        },
      }
    );

    return data;
  },

  async pingOnline({
    userId,
    siteVersion,
  }: {
    userId: string | null;
    siteVersion?: string | null;
  }) {
    const { data } = await httpClient.get('/ping', {
      headers: {
        'X-ULID': userId,
        ...(siteVersion && { 'X-Frontend-Version': siteVersion }),
      },
    });

    return data;
  },

  async pingOnlineV2(type: OnlineType) {
    const { data } = await httpClient.post('/ping/v2', {
      type,
      page_load_id: window.pageLoadId,
    });

    return data;
  },

  async logout() {
    return httpClient.get('/logout');
  },

  async getSiteVersion() {
    try {
      const response = await fetch(`/version.txt?timestamp=${Date.now()}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (response.status === 200) return await response?.text();

      return null;
    } catch {
      return null;
    }
  },
};
