import React from 'react';

import { BasePopupProps } from './types';

export const POPUP_EVENT_NAME = 'POPUP_EVENT';
export const POPUP_QUEUE_STORAGE_KEY = 'POPUP_QUEUE';
export const POPUP_QUEUE_DELAY = 10000; // 10 sec

export const REGISTER_POPUPS: React.ComponentType<BasePopupProps<any, any>>[] =
  [];
