import { FinderFilters } from 'api/FinderApi';

export const serializeFilters = (filters: any): FinderFilters | null => {
  return {
    gender: filters.gender,
    age_from: filters.age_from,
    age_to: filters.age_to,
    ...(filters.country?.id && { country_id: filters.country?.id }),
  };
};
