import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { rootReducer, RootState } from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
});

// @ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  // @ts-ignore
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const { rootReducer: newRootReducer } = require('./rootReducer');
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, undefined, Action<string>>;
