import { useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MIRRORS_THEMES } from 'themes';

import { MirrorService } from 'services/MirrorService';

import { getIsEnabledDarkThemeSelector } from 'store/common/selectors';

export const useMirrorTheme = (variables: Record<string, string>) => {
  const rootRef = useRef<any>(null);

  // TODO review this logic for incoming sites
  useEffect(() => {
    Object.entries(variables).forEach(([varName, varValue]) => {
      if (rootRef.current) {
        rootRef.current.style.setProperty(varName, varValue);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return rootRef;
};

export const useMirrorThemeColors = () => {
  const isEnabledDarkTheme = useSelector(getIsEnabledDarkThemeSelector);

  useLayoutEffect(() => {
    const currentThemeColors = isEnabledDarkTheme
      ? MIRRORS_THEMES.dark[MirrorService.domain]
      : MIRRORS_THEMES.light[MirrorService.domain];

    if (currentThemeColors) {
      Object.entries(currentThemeColors).forEach(([name, value]) => {
        document.body.style.setProperty(`--${name}`, value);
      });
    }
  }, [isEnabledDarkTheme]);

  useEffect(() => {
    document.body.classList.add('grey-bg');

    return () => {
      document.body.classList.remove('grey-bg');
    };
  }, []);
};
