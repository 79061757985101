import { useQuery, UseQueryOptions } from 'react-query';

import { AuthApi } from 'api/AuthApi';

export const WEB_SOCKET_TOKEN_QUERY_KEY = 'web-socket-token-query';

export const useWSConnectionTokenQuery = (
  queryOptions?: UseQueryOptions<{ token: string }>
) => {
  return useQuery<{ token: string }>(
    WEB_SOCKET_TOKEN_QUERY_KEY,
    () => AuthApi.fetchWSConnectionToken(),
    {
      retry: false,
      ...queryOptions,
    }
  );
};
