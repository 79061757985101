import { ChatDialogSaveStatus } from 'types/enums/chat/ChatDialogSaveStatus';
import { ChatMessageFormat } from 'types/enums/chat/ChatMessageFormat';
import { ChatDialog } from 'types/interfaces/chat/ChatDialog';
import { ChatDialogFilters } from 'types/interfaces/chat/ChatDialogFilters';
import { ChatDialogTabCounters } from 'types/interfaces/chat/ChatDialogTabCounters';
import { ChatGift } from 'types/interfaces/chat/ChatGift';
import { ChatLimits } from 'types/interfaces/chat/ChatLimits';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { ChatStickerPack } from 'types/interfaces/chat/ChatStickerPack';
import { UserContact } from 'types/interfaces/user/UserContact';

import { httpClient } from './httpClient';

interface DialogsResponse {
  data: ChatDialog[];
  tab_counters: Record<string, ChatDialogTabCounters>;
  next: string | null;
}

interface DialogResponse {
  data: ChatDialog;
}

interface MessagesResponse {
  user: UserContact;
  contact: UserContact;
  dialog: ChatDialog;
  messages: ChatMessage[];
  next: string | null;
  is_liked: boolean;
  blocked_me: boolean;
  spent: {
    over_30: boolean;
  };
  limits: ChatLimits;
  connection_status: boolean;
}

interface SendTextMessagePayload {
  contactId: string;
  body: string;
  frontMessageId: string;
}

interface SendPhotoMessagePayload {
  format: ChatMessageFormat.Photo;
  contactId: string;
  photoId: number;
  frontMessageId: string;
}

interface SendStickerMessagePayload {
  contactId: string;
  stickerId: number;
  frontMessageId: string;
}

interface SendGiftMessagePayload {
  contactId: string;
  giftId: number;
  body: string;
  frontMessageId: string;
}

const readIncomeMessage = (message: ChatMessage) => {
  if (!message.is_incoming) {
    return message;
  }

  return {
    ...message,
    read_at: new Date().toUTCString(),
  };
};

export const DialogsApi = {
  async fetchNextDialogs(url: string | null) {
    const { data } = await httpClient.get<DialogsResponse>(url || '/dialogs', {
      headers: {
        'X-ULID': null,
      },
    });

    return data;
  },

  async fetchOneDialog(payload: { contactId: string; userId: string }) {
    const { data } = await httpClient.get<DialogResponse>(
      `/dialog/${payload.contactId}`,
      {
        headers: {
          'X-ULID': payload.userId,
        },
      }
    );

    return data;
  },

  async fetchDialogsWithFilters(filters: ChatDialogFilters) {
    const { data } = await httpClient.get<DialogsResponse>(
      `/dialogs?search=${filters.search}&tab=${filters.tab}&is_online=${
        filters.is_online ? 1 : 0
      }`,
      {
        headers: {
          'X-ULID': null,
        },
      }
    );

    return data;
  },

  async updateDialogHideStatus(contactId: string, shouldHide: boolean) {
    const { data } = await httpClient.post<{ status: boolean }>(
      '/dialogs/hide',
      {
        contact_id: contactId,
        hide: shouldHide,
      }
    );

    return data;
  },

  async updateDialogSaveStatus({
    isSaved,
    contactId,
  }: {
    isSaved: ChatDialogSaveStatus;
    contactId: string;
  }) {
    const { data } = await httpClient.post('/dialogs/save', {
      contact_id: contactId,
      save: isSaved,
    });

    return data;
  },

  async fetchMessages(contactId: string) {
    const { data } = await httpClient.get<MessagesResponse>(
      `/dialogs/${contactId}`
    );

    return {
      ...data,
      messages: data.messages.map(readIncomeMessage),
    };
  },

  async fetchMoreMessages(url: string) {
    const { data } = await httpClient.get<MessagesResponse>(url);

    return data;
  },

  async readIncomingMessages({
    contactId,
    messageId,
  }: {
    contactId: string;
    messageId: number;
  }) {
    const { data } = await httpClient.post(
      `/messages/${contactId}/read/${messageId}`
    );

    return data;
  },

  async sendTextMessage(payload: SendTextMessagePayload) {
    return httpClient.post<{ message: ChatMessage }>('/messages', {
      format: ChatMessageFormat.Text,
      body: payload.body,
      contact_id: payload.contactId,
      front_message_id: payload.frontMessageId,
    });
  },

  async sendPhotoMessage(payload: SendPhotoMessagePayload) {
    return httpClient.post<{ message: ChatMessage }>('/messages', {
      format: ChatMessageFormat.Photo,
      photo_id: payload.photoId,
      contact_id: payload.contactId,
      front_message_id: payload.frontMessageId,
    });
  },

  async sendStickerMessage(payload: SendStickerMessagePayload) {
    return httpClient.post<{ message: ChatMessage }>('/messages', {
      format: ChatMessageFormat.Sticker,
      sticker_id: payload.stickerId,
      contact_id: payload.contactId,
      front_message_id: payload.frontMessageId,
    });
  },

  async sendGiftMessage(payload: SendGiftMessagePayload) {
    return httpClient.post<{ message: ChatMessage }>('/messages', {
      format: ChatMessageFormat.Gift,
      contact_id: payload.contactId,
      gift_id: payload.giftId,
      body: payload.body,
      front_message_id: payload.frontMessageId,
    });
  },

  async retrySendMessage({
    contactId,
    messageId,
  }: {
    contactId: string;
    messageId: number;
  }) {
    const { data } = await httpClient.post<{ message: ChatMessage }>(
      `/messages/${contactId}/retry/${messageId}`
    );

    return data;
  },

  async fetchGifts() {
    const { data } = await httpClient.get<{ data: ChatGift[] }>('/gifts');

    return data;
  },

  async fetchStickerPacks() {
    const { data } = await httpClient.get<{ data: ChatStickerPack[] }>(
      '/sticker-packs'
    );

    return data;
  },

  async startTyping({ contactId }: { contactId: string }) {
    return httpClient.get(`/messages/typing/${contactId}`);
  },

  async openGift({
    contactId,
    messageId,
  }: {
    contactId: string;
    messageId: number;
  }) {
    return httpClient.post(`/messages/${contactId}/open-gift/${messageId} `);
  },
};
