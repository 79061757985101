import cookie from 'js-cookie';

import { MirrorService } from 'services/MirrorService';

export const getUserIdFromCookies = () => cookie.get('tru_userid');

export const setUserIdToCookies = (id: string) => {
  const currentDomain = MirrorService.domainWithoutSubdomain;

  cookie.set('tru_userid', id, {
    expires: 7 * 4,
    domain: `.${currentDomain}`,
  });
};

export const removeUserIdFromCookies = () => {
  cookie.remove('tru_userid', {
    domain: window.location.hostname.split('.').slice(-2).join('.'),
  });
};
