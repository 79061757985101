import { AppThunk } from 'store';

import { NotificationsApi } from 'api/NotificationsApi';

import {
  setIsNotificationsFirstLoaded,
  setIsNotificationsLoading,
  setNotifications,
  setNotificationStatusRead,
} from './notificationsSlice';

export const fetchNotificationsThunk = (): AppThunk => async (dispatch) => {
  dispatch(setIsNotificationsLoading(true));

  const data = await NotificationsApi.fetchNotifications();

  dispatch(setNotifications(data.notifications));
  dispatch(setIsNotificationsLoading(false));
  dispatch(setIsNotificationsFirstLoaded());
};

export const readNotificationThunk =
  (payload: { notificationId: number }): AppThunk =>
  async (dispatch) => {
    await NotificationsApi.readNotification({
      notificationId: payload.notificationId,
    });

    dispatch(
      setNotificationStatusRead({ notificationId: payload.notificationId })
    );
  };
