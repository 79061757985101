import { useEffect, useMemo, useRef, useState } from 'react';

export const useInactiveBrowserTab = () => {
  const isBrowserTabActiveRef = useRef(true);

  const [isBrowserTabActive, setIsBrowserTabActive] = useState(true);

  const { keyName, eventName } = useMemo(() => {
    let keyNameValue = '';
    let eventNameValue = '';

    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      keyNameValue = 'hidden';
      eventNameValue = 'visibilitychange';
      // @ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
      keyNameValue = 'msHidden';
      eventNameValue = 'msvisibilitychange';
      // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
      keyNameValue = 'webkitHidden';
      eventNameValue = 'webkitvisibilitychange';
    }

    return {
      keyName: keyNameValue,
      eventName: eventNameValue,
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      // @ts-ignore
      setIsBrowserTabActive(!document[keyName]);
      // @ts-ignore
      isBrowserTabActiveRef.current = !document[keyName];
    };

    if (typeof document.addEventListener && keyName) {
      document.addEventListener(eventName, handleVisibilityChange, false);
    }

    return () => {
      document.removeEventListener(eventName, handleVisibilityChange);
    };
  }, [eventName, keyName]);

  return {
    isBrowserTabActive,
    isBrowserTabActiveRef,
  };
};
