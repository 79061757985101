import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Photo } from 'types/interfaces/Photo';
import { User } from 'types/interfaces/user/User';

import {
  getUserIdFromCookies,
  removeUserIdFromCookies,
  setUserIdToCookies,
} from 'helpers/userId';

interface AuthState {
  user: User | null;
  loading: boolean;
  authenticated: boolean | null;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  authenticated: !!getUserIdFromCookies(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStart(state) {
      state.loading = true;
    },

    authFailed(state) {
      state.loading = false;
    },

    authSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.loading = false;
    },

    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
    },

    setAuthenticated(state, action: PayloadAction<{ userId: string | null }>) {
      if (action.payload.userId) {
        setUserIdToCookies(action.payload.userId);
      } else {
        removeUserIdFromCookies();
      }

      state.authenticated = !!action.payload.userId;
    },

    updateUser(state, action: PayloadAction<Partial<User>>) {
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      }
    },
    addUserPhoto(state, action: PayloadAction<Photo>) {
      if (state.user) {
        state.user = {
          ...state.user,
          photos: [...(state.user.photos || []), action.payload],
        };
      }
    },
  },
});

export const {
  authStart,
  authFailed,
  authSuccess,
  setUser,
  setAuthenticated,
  updateUser,
  addUserPhoto,
} = authSlice.actions;

export const auth = authSlice.reducer;
