import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useSelector } from 'react-redux';

import { MirrorService } from 'services/MirrorService';

import { getUser } from 'store/auth/selectors';

export const useHotjar = (isAppInitialized: boolean) => {
  const user = useSelector(getUser);

  useEffect(() => {
    if (isAppInitialized && MirrorService.isProd && user?.ulid_id) {
      hotjar.initialize({ id: 4988871, sv: 6 });
      hotjar.identify(user.ulid_id, {});
    }
  }, [isAppInitialized, user?.ulid_id]);
};
