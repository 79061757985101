import React from 'react';
import { Link } from 'react-router-dom';

import { getCurrentLogo } from './utils';

import css from './logo.module.sass';

interface Props {
  dark?: boolean;
  className?: string;
}

export const Logo: React.FC<Props> = ({ dark = false, className }) => {
  const height = '25px';

  const LogoIcon = getCurrentLogo({
    isDark: dark,
  });

  if (!LogoIcon) {
    return null;
  }

  return (
    <Link to="/" className={css.link}>
      <LogoIcon height={height} className={className} />
    </Link>
  );
};
