import * as Sentry from '@sentry/react';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { MirrorService } from 'services/MirrorService';

import { setXPrevUrl, setXRefererUrl } from 'api/httpClient';
import { setTrackingCookiesFromUrl } from 'helpers/analytics/trackingCookies';

import { AppContainer } from './AppContainer';

import 'context-filter-polyfill';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'styles/main.sass';

MirrorService.init();

Sentry.init({
  dsn: 'https://4009f49cf186b5e96afe402bd027e9c0@o4506705060888576.ingest.us.sentry.io/4507815199965185',
  integrations: [Sentry.browserTracingIntegration()],
  sampleRate: 0.01,
  tracesSampleRate: 0.01,
  denyUrls: [/spiddy.co/i],
});

setXPrevUrl(document.referrer);
setXRefererUrl(window.location.href);

setTrackingCookiesFromUrl();

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(<AppContainer />);
