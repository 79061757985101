import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setupInterceptors } from 'api/httpInterceptors';

export const useInterceptors = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setupInterceptors({ dispatch, history });
  }, [dispatch, history]);
};
