export enum PresentStatus {
  Requested = 1,
  Refused = 2,
  Sent = 3,
  Received = 4,
  Processing = 5,
  Insuf = 6,
  Moderation = 7,
  Decline = 8,
  Approved = 9,
  ReModeration = 10,
}
