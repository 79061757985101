import { AppEnv } from 'types/enums/AppEnv';
import { AppEnvUrlPart } from 'types/enums/AppEnvUrlPart';

import { getLocalStorageItem } from 'helpers/localStorage';

import {
  DEFAULT_DEV_SITE_DOMAIN,
  DEFAULT_PRODUCTION_SITE_DOMAIN,
  DEFAULT_SITE_ID,
  SITES_CONFIGS,
} from './constants';

export const getDevHostnameParts = (hostname: string) => {
  const reg = new RegExp(
    `(${AppEnvUrlPart.Stage}|${AppEnvUrlPart.Dev}|${AppEnvUrlPart.Pid}-\\d+)-s(\\d+)\\.(${DEFAULT_DEV_SITE_DOMAIN})`
  );
  const parts = hostname.match(reg);

  return {
    env: parts ? parts[1] : AppEnv.Prod,
    id: parts ? parts[2] : String(DEFAULT_SITE_ID),
    domain: parts ? parts[3] : DEFAULT_PRODUCTION_SITE_DOMAIN,
  };
};

export const getEnvByHostname = (hostname: string) => {
  const { env: envPart } = getDevHostnameParts(hostname);

  if (envPart === AppEnvUrlPart.Dev) {
    return AppEnv.Dev;
  }

  if (envPart === AppEnvUrlPart.Stage) {
    return AppEnv.Stage;
  }

  if (envPart.startsWith(AppEnvUrlPart.Pid)) {
    return AppEnv.Pid;
  }

  return AppEnv.Prod;
};

const getSiteConfigById = (id: string) => {
  const finedSite = Object.values(SITES_CONFIGS).find(
    (site) => site.id === parseInt(id, 10)
  );

  return finedSite || SITES_CONFIGS.ChatsHouse;
};

const getSiteConfigByDomain = (domain: string) => {
  const finedSite = Object.values(SITES_CONFIGS).find(
    (site) => site.domain === domain
  );

  return finedSite || SITES_CONFIGS.ChatsHouse;
};

export const getSiteHostnameById = (id?: number): string | null => {
  const finedSiteHostname = Object.values(SITES_CONFIGS).find(
    (site) => site.id === id
  );

  return finedSiteHostname?.domain || 'unknown';
};

export const getSiteConfigByHostname = (hostname: string) => {
  const env = getEnvByHostname(hostname);

  if ([AppEnv.Dev, AppEnv.Stage, AppEnv.Pid].includes(env)) {
    const hostnameParts = getDevHostnameParts(hostname);
    const siteIdForTest = getLocalStorageItem('dev-site-id');
    const id = siteIdForTest || hostnameParts.id;

    return getSiteConfigById(id);
  }

  return getSiteConfigByDomain(hostname);
};

export const getSiteApiUrl = (hostname: string): string => {
  const env = getEnvByHostname(hostname);

  if (env === AppEnv.Prod) {
    return `https://api.${hostname}/tru`;
  }

  const { env: envPart, domain: domainPart } = getDevHostnameParts(hostname);

  return `https://${envPart}-api-tu.${domainPart}/tru`;
};

export const getSiteWsUrl = (hostname: string): string => {
  const env = getEnvByHostname(hostname);

  if (env === AppEnv.Prod) {
    return `wss://ws.${hostname}`;
  }

  const { env: envPart, domain: domainPart } = getDevHostnameParts(hostname);

  return `wss://${envPart}-ws.${domainPart}`;
};

export const getDefaultSiteApiUrl = (hostname: string): string => {
  const env = getEnvByHostname(hostname);

  if (env === AppEnv.Prod) {
    return `https://api.${DEFAULT_PRODUCTION_SITE_DOMAIN}`;
  }

  const { env: envPart } = getDevHostnameParts(hostname);

  return `https://${envPart}-api-tu.${DEFAULT_DEV_SITE_DOMAIN}`;
};

export const getTranslationsBaseUrl = (hostname: string): string => {
  const env = getEnvByHostname(hostname);

  if (env === AppEnv.Prod) {
    return `https://translations.${hostname}`;
  }

  return `https://translations.${DEFAULT_DEV_SITE_DOMAIN}`;
};

export const getApiUrl = (hostname: string) => {
  return hostname.replace(/-s\d{1,20}/g, '');
};
