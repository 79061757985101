import { AppDispatch, AppThunk } from 'store';

import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { MailsFilters } from 'types/interfaces/mails/MailsFilters';
import { UserContact } from 'types/interfaces/user/UserContact';

import { MailsApi } from 'api/MailsApi';

import {
  addMail,
  addMails,
  addMailsChains,
  setMails,
  setMailsChains,
  setMailsChainsLoading,
  setMailsInitLoading,
  setNextMailsChains,
  updateMailsChainWithNewMail,
} from './mailsSlice';

const MIN_CREDITS_SPEND_FOR_EROTIC = 5;

export const fetchMailsChainsThunk =
  (payload: MailsFilters): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setMailsChainsLoading(true));

    const { data: mailsChains, next } =
      await MailsApi.fetchMailsChains(payload);

    dispatch(setMailsChains(mailsChains));
    dispatch(setNextMailsChains(next));
    dispatch(setMailsChainsLoading(false));
  };

export const fetchMoreMailsChainsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { inbox } = getState();
    const nextUrl = inbox.nextMailsChains;

    if (!nextUrl) return;

    const { data: mailsChains, next } =
      await MailsApi.fetchMoreMailsChains(nextUrl);

    dispatch(addMailsChains(mailsChains));
    dispatch(setNextMailsChains(next));
  };

export const fetchMailsThunk =
  (payload: { contactId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setMailsInitLoading(true));

    const mailsChainResponse = await MailsApi.fetchMails(payload.contactId);

    dispatch(
      setMails({
        user: mailsChainResponse.user,
        contact: mailsChainResponse.contact,
        messages: mailsChainResponse.inmails,
        next: mailsChainResponse.next,
        limits: mailsChainResponse.limits,
        isLiked: mailsChainResponse.is_liked,
        isBlocked: mailsChainResponse.blocked_me,
        isConnection: !!mailsChainResponse?.connection_status,
        isEnabledEroticMedia:
          mailsChainResponse.contact?.credits?.spend === '20+' ||
          (mailsChainResponse.contact?.credits?.spend || 0) >=
            MIN_CREDITS_SPEND_FOR_EROTIC,
      })
    );
    dispatch(setMailsInitLoading(false));
  };

export const fetchMoreMailsThunk =
  (payload: { userId: string; contactId: string }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { contactId, userId } = payload;
    const { inbox } = getState();

    const nextUrl = inbox.mails[`${contactId}-${userId}`].next;

    if (!nextUrl) return;

    const mailsChainResponse = await MailsApi.fetchMoreMails(nextUrl);

    dispatch(
      addMails({
        userId,
        contactId,
        messages: mailsChainResponse.inmails,
        next: mailsChainResponse.next,
      })
    );
  };

export const sendTextMailThunk =
  (payload: {
    contact: UserContact;
    body: string;
    photosIds: number[];
  }): AppThunk =>
  async () => {
    await MailsApi.sendTextMail({
      contactId: payload.contact.ulid_id,
      body: payload.body,
      photosIds: payload.photosIds,
    });
  };

export const addNewMailFromSocketThunk =
  (payload: { message: Mail; userId: string; contactId: string }): AppThunk =>
  (dispatch: AppDispatch, getState) => {
    const { mailsChains } = getState().inbox;

    dispatch(addMail(payload));

    const hasMailsChainWith =
      (contactId: string, userId: string) => (mailsChain: MailsChain) =>
        mailsChain.contact.ulid_id === contactId &&
        mailsChain.user?.ulid_id === userId;

    if (
      mailsChains.some(hasMailsChainWith(payload.contactId, payload.userId))
    ) {
      dispatch(updateMailsChainWithNewMail(payload));
    }
  };
