import { useMediaQuery } from 'react-responsive';

export const useMedia = () => {
  const isDesktop = useMediaQuery({ minWidth: 1280 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDefault = useMediaQuery({ minWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });

  return {
    isDesktop,
    isMobile,
    isDefault,
    isTablet,
  };
};
