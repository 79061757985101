import { useEffect, useState } from 'react';

import { useI18n } from 'hooks/useI18n';

export const useInitializeApp = () => {
  const { initI18n } = useI18n();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      await initI18n();

      setIsInitialized(true);
    };

    initialize();
  }, [initI18n]);

  return {
    isInitialized,
  };
};
