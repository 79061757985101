export enum NotificationTypes {
  Visit = 1,
  Message = 2,
  Like = 3,
  MutualLike = 4,
  ProfileNew = 5,
  ProfileRecommended = 6,
  ProfilePopular = 7,
  AddedAsFavorite = 8,
  PhotoApproved = 9,
  PhotoRejected = 10,
  Inbox = 11,
}

export enum NotificationActionTypes {
  DoNothing = 'do_nothing',
  OpenProfile = 'open_profile',
  OpenDialog = 'open_dialog',
  OpenContactUs = 'open_contact_us',
  OpenVideosPage = 'open_videos_page',
}

export enum NotificationImageType {
  Sender = 'sender',
  SystemSuccess = 'system_success',
  SystemError = 'system_error',
  SystemWarning = 'system_warning',
}

export enum NewNotificationsCounterKeys {
  Visits = 'newVisits',
  Likes = 'newLikes',
  Matches = 'newMatches',
}
