import { UserGender } from 'types/enums/UserGender';
import { Country } from 'types/interfaces/Location';
import { UserCard } from 'types/interfaces/user/UserCard';

import { httpClient } from './httpClient';
import { serializeFilters } from './serialization/finderApi';

export const DEFAULT_FINDER_FILTERS: FinderFilters = {
  age_from: 18,
  age_to: 100,
  gender: UserGender.Male,
  country: null,
  country_id: null,
};

export type FinderFilters = {
  age_from: number;
  age_to: number;
  gender: UserGender;
  country?: Country | null;
  country_id?: number | null;
};

export type FinderUsersResponse = {
  data: UserCard[];
};

export type FetchPublicUsersPayload = {
  filters: FinderFilters;
  url?: string;
};

export interface FinderVideo {
  id: string;
  username: string;
  age: number;
  gender: string;
  is_popular: boolean;
  profile_photo_url: string;
  video_id: number;
  video_url: string;
}

export const FinderApi = {
  async fetchFinderUsers({
    filters,
    page,
  }: {
    filters: FinderFilters;
    page: number;
  }) {
    const { data } = await httpClient.post<FinderUsersResponse>(
      '/finder/eav-find',
      {
        ...serializeFilters(filters),
        page,
      }
    );

    return data;
  },
};
