import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';

import { POPUP_EVENT_NAME, POPUP_QUEUE_STORAGE_KEY } from './contstants';
import { PopupEventPayload, PopupQuenue } from './types';

export const getPopupQueueFromStorage = (): PopupQuenue[] => {
  const queue = getSessionStorageItem(POPUP_QUEUE_STORAGE_KEY);

  return queue ? JSON.parse(queue) : [];
};

export const setPopupQueueToStorage = (queue: PopupQuenue[]) => {
  setSessionStorageItem(POPUP_QUEUE_STORAGE_KEY, JSON.stringify(queue));
};

export const addPopupToStorageQueue = (popup: PopupQuenue) => {
  const queue = getPopupQueueFromStorage();
  const updatedQueue = queue.concat([popup]);

  setSessionStorageItem(POPUP_QUEUE_STORAGE_KEY, JSON.stringify(updatedQueue));
};

export const clearPopupStorageQueue = () => {
  setSessionStorageItem(POPUP_QUEUE_STORAGE_KEY, JSON.stringify([]));
};

export const createPopupEvent = ({ popup, action }: PopupEventPayload) => {
  const event = new CustomEvent(POPUP_EVENT_NAME, {
    detail: {
      popup,
      action,
    },
  });

  document.dispatchEvent(event);
};
