import React from 'react';

import { ReactComponent as NotificationErrorIcon } from 'assets/icons/notification-error.svg';
import { ReactComponent as NotificationSuccessIcon } from 'assets/icons/notification-success.svg';
import { ReactComponent as NotificationWarningIcon } from 'assets/icons/notification-warning.svg';

import css from './systemToast.module.sass';

export enum SystemToastTypes {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export interface SystemToastProps {
  type?: SystemToastTypes;
  title: string;
  subtitle?: string;
  onClick?: () => void;
}

const ICONS_MAP = {
  [SystemToastTypes.Success]: NotificationSuccessIcon,
  [SystemToastTypes.Warning]: NotificationWarningIcon,
  [SystemToastTypes.Error]: NotificationErrorIcon,
};

export const SystemToast: React.FC<SystemToastProps> = ({
  type,
  title,
  subtitle,
  onClick,
}) => {
  const Icon = type ? ICONS_MAP[type] : NotificationSuccessIcon;

  return (
    <div className={css.root} onClick={onClick}>
      <Icon className={css.icon} />

      <div className={css.content}>
        <p className={css.title}>{title}</p>
        {subtitle && <p className={css.subtitle}>{subtitle}</p>}
      </div>
    </div>
  );
};
