import { logoutThunk } from 'store/auth/thunks';

import { httpClient } from './httpClient';

export const setupInterceptors = ({
  dispatch,
  history,
}: {
  dispatch: any;
  history: any;
}) => {
  httpClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error?.response?.status;

      if (
        status &&
        status === 401 &&
        !error?.response?.request?.responseURL?.includes('/tru/logout')
      ) {
        await dispatch(logoutThunk({ withApi: false }));

        history.push('/login');
      }

      return Promise.reject(error);
    }
  );
};
