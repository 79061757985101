import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { tabBadge } from 'helpers/browserBadge';
import { isDialogsPage } from 'helpers/route';
import { getNewDialogsCount } from 'store/common/selectors';

export const useBrowserBadge = () => {
  const { pathname } = useLocation();

  const newDialogsCount = useSelector(getNewDialogsCount);

  useEffect(() => {
    const faviconElement = document.querySelector(
      'link[rel$=icon][sizes="32x32"]'
    );

    tabBadge.updateFaviconEl(faviconElement);
  }, []);

  useEffect(() => {
    if (isDialogsPage(pathname)) {
      tabBadge.updateNotificationCounter(0);
    } else {
      tabBadge.updateNotificationCounter(newDialogsCount);
    }
  }, [newDialogsCount, pathname]);
};
