import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getActiveSystemPopupParamsSelector,
  getActiveSystemPopupSelector,
} from 'store/systemPopup/selectors';
import { closeSystemPopup } from 'store/systemPopup/systemPopupSlice';

import { SYSTEM_POPUPS_MAP } from './constants';

export const SystemPopup = () => {
  const dispatch = useDispatch();
  const activePopupType = useSelector(getActiveSystemPopupSelector);
  const params = useSelector(getActiveSystemPopupParamsSelector);

  const handleCloseActivePopup = () => {
    dispatch(closeSystemPopup());
  };

  if (!activePopupType) {
    return null;
  }

  const ActivePopup = SYSTEM_POPUPS_MAP[activePopupType];

  return <ActivePopup onClose={handleCloseActivePopup} params={params} />;
};
