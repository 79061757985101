export const getScreenSizes = (): { width: number; height: number } => {
  const width = window.screen.availWidth;
  const height = window.screen.availHeight;

  return { width, height };
};

export const getScreenOrientation = (): 'portrait' | 'landscape' => {
  return window.matchMedia('(orientation: portrait)').matches
    ? 'portrait'
    : 'landscape';
};

export const getScreenOrientationWithSizes = () => {
  return {
    orientation: getScreenOrientation(),
    width: getScreenSizes().width,
    height: getScreenSizes().height,
  };
};
