import { useCallback, useEffect, useRef } from 'react';

import { AuthApi, OnlineType } from 'api/AuthApi';

import { useInactiveBrowserTab } from './useInactiveBrowserTab';

const PING_TIMEOUT = 60 * 1000;

export const useOnlinePingV2 = (isAuthenticated: boolean) => {
  const lastPing = useRef<number>(0);

  const { isBrowserTabActive } = useInactiveBrowserTab();

  const handlePingOnlineSend = useCallback(() => {
    if (!isAuthenticated) return;

    const eventTs = Date.now();

    if (eventTs - lastPing.current > PING_TIMEOUT) {
      lastPing.current = eventTs;

      AuthApi.pingOnlineV2(OnlineType.RealOnline);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isBrowserTabActive) {
      handlePingOnlineSend();
    }
  }, [handlePingOnlineSend, isBrowserTabActive]);

  useEffect(() => {
    document.addEventListener('click', handlePingOnlineSend);
    document.addEventListener('scroll', handlePingOnlineSend);
    document.addEventListener('mousemove', handlePingOnlineSend);
    document.addEventListener('keydown', handlePingOnlineSend);
    document.addEventListener('touchstart', handlePingOnlineSend);

    return () => {
      document.removeEventListener('click', handlePingOnlineSend);
      document.removeEventListener('scroll', handlePingOnlineSend);
      document.removeEventListener('mousemove', handlePingOnlineSend);
      document.removeEventListener('keydown', handlePingOnlineSend);
      document.removeEventListener('touchstart', handlePingOnlineSend);
    };
  }, [handlePingOnlineSend]);
};
