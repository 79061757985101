import { SoundNotification } from 'types/interfaces/Notifications';

import { httpClient } from './httpClient';

type AccountSettings = {
  email: {
    name: string;
    value: string;
  };
  id: {
    name: string;
    value: number;
  };
  notifications: {
    name: string;
    value: boolean;
  };
  password: {
    name: string;
    value: string;
  };
};

export interface ContactUsPayload {
  email?: string;
  message: string;
}

export const SettingsApi = {
  async fetchSettings() {
    const { data } = await httpClient.get<AccountSettings>('/user/settings');

    return data;
  },

  async sendEmailVerification() {
    const { data } = await httpClient.patch(
      '/user/settings/email-verification'
    );

    return data;
  },

  async changeEmail(payload: { email: string }) {
    return httpClient.patch('/user/settings/email', payload);
  },

  async changePassword(payload: { password: string }) {
    return httpClient.patch('/user/settings/password', payload);
  },

  async changeSubscription(payload: { receive_email: boolean }) {
    return httpClient.patch('/user/settings/subscription', payload);
  },

  async sendEmailToSupport(payload: ContactUsPayload) {
    const { data } = await httpClient.post('/contact-us', payload);

    return data;
  },

  async unsubscribeFromMailing() {
    const { data } = await httpClient.get('/user/do-not-sell');

    return data;
  },

  async deleteProfile() {
    return httpClient.delete('/user');
  },

  async fetchTerms() {
    const { data } = await httpClient.get<{ terms: string }>('/terms');

    return data;
  },

  async fetchPrivacyPolicy() {
    const { data } = await httpClient.get<{ policy: string }>('/policy');

    return data;
  },

  async fetchTrackingPolicy() {
    const { data } = await httpClient.get<{ policy: string }>(
      '/tracking-policy'
    );

    return data;
  },

  async fetchTrustedUsePolicy() {
    const { data } = await httpClient.get<{ terms: string }>('/trusted-policy');

    return data;
  },

  async fetchComplianceStatement() {
    const { data } = await httpClient.get<{ policy: string }>(
      '/compliance-statement'
    );

    return data;
  },

  async fetchDisclosuresDisclaimers() {
    const { data } = await httpClient.get<{ terms: string }>(
      '/disclosures-disclaimers'
    );

    return data;
  },

  async fetchScamPolicy() {
    const { data } = await httpClient.get<{ terms: string }>('/scam-policy');

    return data;
  },

  async fetchSiteInfo() {
    const { data } = await httpClient.get<{ address: string }>('/site-info');

    return data;
  },

  async updateNotificationsSoundFlag(payload: SoundNotification) {
    return httpClient.post('/settings/sound-notification', payload, {
      headers: {
        'X-ULID': null,
      },
    });
  },
};
