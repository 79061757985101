import { AppThunk } from 'store';

import { SoundNotification } from 'types/interfaces/Notifications';

import { AuthApi } from 'api/AuthApi';
import { SettingsApi } from 'api/SettingsApi';
import { setUser } from 'store/auth/authSlice';

import { setInitState, setLoading, setNotificationsSound } from './commonSlice';

export const init = (): AppThunk => async (dispatch, getState) => {
  const state = getState();

  dispatch(setLoading(true));

  const initData = await AuthApi.init({
    from: state.common.prevPagePathname,
  });

  dispatch(
    setInitState({
      loading: false,
      presentsRequestsCount: initData.real_gift_requested_counter,
      unreadCount: initData?.unread_counters || {},
      currentLang: initData.site_language?.value,
      supportedLangs: Object.values(initData.site_language.options),
      sound_notification:
        typeof initData.sound_notification === 'object'
          ? initData.sound_notification
          : {
              received_message: initData.sound_notification,
              sent_message: initData.sound_notification,
              received_mail: initData.sound_notification,
              sent_mail: initData.sound_notification,
              received_like: initData.sound_notification,
              sent_like: initData.sound_notification,
            },
      featureFlags: {
        isEnabledIcebreakersChains: initData.is_enabled_icebreakers_chains,
        isEnabledPresents: initData.is_real_gift_enabled,
      },
    })
  );

  dispatch(setUser(initData.user));
};

export const updateNotificationsSoundThunk =
  (payload: SoundNotification): AppThunk =>
  async (dispatch) => {
    await SettingsApi.updateNotificationsSoundFlag(payload);

    dispatch(setNotificationsSound(payload));
  };
