import React, { memo, PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Spinner, SpinnerTypes } from '../Spinner';

import css from './baseButton.module.sass';

export enum ButtonTypes {
  Accent,
  Contrast,
  Secondary,
  Transparent,
  Regular,
  OutlinePrimary,
  OutlineSecondary,
}

interface Props {
  loading?: boolean;
  nativeType?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
  to?: any;
  type?: ButtonTypes;
  className?: string;
  ariaLabel?: string;
  small?: boolean;
  autoHeight?: boolean;
  loaderSize?: string;
  onClick?: (event?: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const TYPES_WITH_DARK_SPINNER = [
  ButtonTypes.Transparent,
  ButtonTypes.Contrast,
  ButtonTypes.Secondary,
  ButtonTypes.OutlinePrimary,
  ButtonTypes.OutlineSecondary,
];

const BUTTON_TYPE_CLASS_MAP = {
  [ButtonTypes.Accent]: css.btnAccent,
  [ButtonTypes.Contrast]: css.btnContrast,
  [ButtonTypes.Secondary]: css.btnSecondary,
  [ButtonTypes.Transparent]: css.btnTransparent,
  [ButtonTypes.Regular]: css.btnRegular,
  [ButtonTypes.OutlinePrimary]: css.btnOutlinePrimary,
  [ButtonTypes.OutlineSecondary]: css.btnOutlineSecondary,
};

export const BaseButton: React.FC<PropsWithChildren<Props>> = memo(
  ({
    nativeType = 'button',
    loading = false,
    disabled = false,
    to,
    type = ButtonTypes.Accent,
    className,
    ariaLabel,
    small,
    autoHeight,
    loaderSize,
    onClick,
    onMouseEnter,
    onMouseLeave,
    children,
  }) => {
    const classes = useMemo(
      () =>
        cx(
          css.root,
          [BUTTON_TYPE_CLASS_MAP[type]],
          {
            [css.disabled]: disabled || loading,
            [css.small]: small,
            [css.autoHeight]: autoHeight,
          },
          className
        ),
      [autoHeight, className, disabled, loading, small, type]
    );

    const handleLinkBtnCLick = useMemo(
      () =>
        disabled || loading
          ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
              e.preventDefault();
            }
          : onClick,
      [disabled, loading, onClick]
    );

    if (to) {
      return (
        <Link
          to={to}
          aria-label={ariaLabel}
          className={classes}
          onClick={handleLinkBtnCLick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {loading ? (
            <Spinner
              size={loaderSize || '24px'}
              type={
                TYPES_WITH_DARK_SPINNER.includes(type)
                  ? SpinnerTypes.Dark
                  : SpinnerTypes.Light
              }
            />
          ) : (
            children
          )}
        </Link>
      );
    }

    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={nativeType}
        disabled={disabled || loading}
        className={classes}
        aria-label={ariaLabel}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {loading ? (
          <Spinner
            size={loaderSize || '24px'}
            type={
              TYPES_WITH_DARK_SPINNER.includes(type)
                ? SpinnerTypes.Dark
                : SpinnerTypes.Light
            }
          />
        ) : (
          children
        )}
      </button>
    );
  }
);
