import classNames from 'classnames';

import { MirrorService } from 'services/MirrorService';
import { AppDomain } from 'types/enums/AppDomain';

export const getThemeClassNames = (
  defaultClassName: string,
  themeClassNames: Record<string, string>
) => {
  return classNames(defaultClassName, themeClassNames[MirrorService.domain]);
};

export const MIRRORS_THEMES: {
  light: Record<AppDomain, Record<string, any>>;
  dark: Record<AppDomain, Record<string, any>>;
} = {
  light: {
    [AppDomain.ChatsHouse]: {
      'text-primary': '#222222',
      'text-primary-light': '#FFFFFF',
      'text-primary-dark': '#000000',

      'bg-primary': '#FFFFFF',
      'bg-primary-light': '#F5F5F5',
      'bg-primary-dark': '#000000',

      'text-secondary': '#5D5D5D',
      'bg-secondary': '#E9E9E9',

      accent: '#00A9D0',
      'accent-light': '#EBFBFF',

      danger: '#FF0000',
    },
  },
  dark: {
    [AppDomain.ChatsHouse]: {
      'text-primary': '#c2c2c2',
      'text-primary-light': '#FFFFFF',
      'text-primary-dark': '#000000',

      'bg-primary': '#121212',
      'bg-primary-light': '#222222',
      'bg-primary-dark': '#000000',

      'text-secondary': '#cacaca',
      'bg-secondary': '#373737',

      accent: '#00A9D0',
      'accent-light': '#16373E',

      danger: '#FF0000',
    },
  },
};
