export const NO_LIKES_TEXT = 'New likes are coming';

export const COLOR_TAGS_BACKGROUNDS: string[] = [
  '#FFE2DB',
  '#DBFFE5',
  '#DBEEFF',
  '#E7DBFF',
  '#FFDBFE',
];

export const TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY =
  'TRANSLATIONS_BUILD_VERSION';

export const MAX_MAILS_MEDIA_COUNT = 10;
export const MAX_CHAT_MEDIA_COUNT = 1;
