export enum Pages {
  Home = '/',
  Login = '/login',
  Finder = '/finder',
  Dialogs = '/dialogs',
  DialogsTread = '/dialogs/:contactId',
  Mails = '/mails',
  MailsTread = '/mails/:contactId',
  Icebreakers = '/icebreakers',
  Presents = '/presents',
  PresentsStatistic = '/presents/statistic',
  MyProfile = '/my-profile',
  UserProfile = '/user/:userId',
  ContactUs = '/my-profile/contact-us',
  FAQ = '/my-profile/faq',
  Edit = '/my-profile/edit',
  Settings = '/my-profile/settings',
  Notifications = '/my-profile/settings/notifcations-sounds',
  Terms = '/terms',
  DisclosuresDisclaimers = '/disclosures-disclaimers',
  ComplianceStatement = '/compliance-statement',
  TrustedPolicy = '/trusted-policy',
  Policy = '/(privacy|tracking|anti-scam)-policy',
  NotFound = '*',
}
