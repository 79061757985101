import { useCallback } from 'react';

import { setXRefererUrl } from 'api/httpClient';
import { useLocationChange } from 'hooks/useLocationChange';

export const useXRefererUrl = () => {
  const handleSetXRefererUrl = useCallback(
    ({ currentUrl }: { currentUrl: string }) => {
      setXRefererUrl(currentUrl);
    },
    []
  );

  useLocationChange(handleSetXRefererUrl);
};
