import { useEffect } from 'react';

export const usePreventBgScroll = ({ enabled }: { enabled: boolean }) => {
  useEffect(() => {
    if (enabled) {
      document.body.style.setProperty('overflow', 'hidden');
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  });
};
