import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slide, toast, ToastContainer } from 'react-toastify';

import { useMedia } from 'hooks/useMedia';
import { getAuthenticated } from 'store/auth/selectors';
import { setCommonToastError } from 'store/common/commonSlice';
import { getCommonToastErrorMessageSelector } from 'store/common/selectors';

import {
  NotificationToast,
  NotificationToastProps,
  SystemToast,
  SystemToastProps,
  SystemToastTypes,
} from 'components/shared/Toast';

import { MAX_TOASTS_COUNT_AT_TIME, TOAST_AUTOCLOSE_TIME } from './constants';

import css from './styles.module.sass';

const getMessageToastContent = (options: NotificationToastProps) => {
  return <NotificationToast {...options} />;
};

const getSystemToastContent = (options: SystemToastProps) => {
  return <SystemToast {...options} />;
};

export const ToastsContext = createContext<{
  showNotificationToast: (payload: NotificationToastProps) => void;
  showSuccessToast: (payload: Omit<SystemToastProps, 'type'>) => void;
  showWarningToast: (payload: Omit<SystemToastProps, 'type'>) => void;
  showErrorToast: (payload: Omit<SystemToastProps, 'type'>) => void;
}>({
  showNotificationToast: () => {},
  showSuccessToast: () => {},
  showWarningToast: () => {},
  showErrorToast: () => {},
});

export const ToastsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useMedia();

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(getAuthenticated);
  const commonToastErrorMessage = useSelector(
    getCommonToastErrorMessageSelector
  );

  const showNotificationToast = useCallback(
    (options: NotificationToastProps) => {
      const content = getMessageToastContent(options);

      toast(content);
    },
    []
  );

  const showSuccessToast = useCallback(
    (options: Omit<SystemToastProps, 'type'>) => {
      const content = getSystemToastContent({
        type: SystemToastTypes.Success,
        ...options,
      });

      toast(content);
    },
    []
  );

  const showWarningToast = useCallback(
    (options: Omit<SystemToastProps, 'type'>) => {
      const content = getSystemToastContent({
        type: SystemToastTypes.Warning,
        ...options,
      });

      toast(content);
    },
    []
  );

  const showErrorToast = useCallback(
    (options: Omit<SystemToastProps, 'type'>) => {
      const content = getSystemToastContent({
        type: SystemToastTypes.Error,
        ...options,
      });

      toast(content);
    },
    []
  );

  useEffect(() => {
    if (commonToastErrorMessage) {
      showErrorToast({ title: commonToastErrorMessage });

      dispatch(setCommonToastError(null));
    }
  }, [commonToastErrorMessage, dispatch, showErrorToast]);

  useEffect(() => {
    return () => {
      if (isAuthenticated) {
        toast.clearWaitingQueue();
      }
    };
  }, [isAuthenticated]);

  const value = useMemo(() => {
    return {
      showNotificationToast,
      showSuccessToast,
      showWarningToast,
      showErrorToast,
    };
  }, [
    showErrorToast,
    showNotificationToast,
    showSuccessToast,
    showWarningToast,
  ]);

  return (
    <ToastsContext.Provider value={value}>
      {children}
      <ToastContainer
        autoClose={TOAST_AUTOCLOSE_TIME}
        position={isMobile ? 'top-center' : 'top-right'}
        hideProgressBar
        closeOnClick
        limit={MAX_TOASTS_COUNT_AT_TIME}
        transition={Slide}
        className={css.root}
        toastClassName={css.toast}
        closeButton
      />
    </ToastsContext.Provider>
  );
};
