import { useCallback, useContext } from 'react';
import { toast } from 'react-toastify';

import { ToastsContext } from 'contexts/ToastsContext';

export const useToast = () => {
  const {
    showSuccessToast,
    showWarningToast,
    showErrorToast,
    showNotificationToast,
  } = useContext(ToastsContext);

  const clearToastsQueue = useCallback(() => {
    toast.clearWaitingQueue();
  }, []);

  return {
    showSuccessToast,
    showWarningToast,
    showErrorToast,
    showNotificationToast,
    clearToastsQueue,
  };
};
