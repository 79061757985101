import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MailsTab } from 'types/enums/mails/MailsTab';
import { MailStatus } from 'types/enums/mails/MailStatus';
import { ChatLimits } from 'types/interfaces/chat/ChatLimits';
import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { MailsFilters } from 'types/interfaces/mails/MailsFilters';
import { UserContact } from 'types/interfaces/user/UserContact';

import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';

const MAILS_FILTERS_STORAGE_KEY = 'mails_filters';

const DEFAULT_MAILS_FILTERS = {
  search: '',
  tab: MailsTab.All,
};

const initMailsFilters = () => {
  try {
    const mailsFiltersLS = getLocalStorageItem(MAILS_FILTERS_STORAGE_KEY);

    if (!mailsFiltersLS) {
      return DEFAULT_MAILS_FILTERS;
    }

    return JSON.parse(mailsFiltersLS);
  } catch (error) {
    return DEFAULT_MAILS_FILTERS;
  }
};

interface InboxState {
  mailsChains: MailsChain[];
  mailsFilters: MailsFilters;
  mailsChainsLoading: boolean;
  nextMailsChains: string | null;

  mails: Record<
    string,
    {
      next: string | null;
      isLiked: boolean;
      isEnabledEroticMedia: boolean;
      isBlocked: boolean;
      user: UserContact;
      contact: UserContact;
      messages: Mail[];
      limits: ChatLimits;
      isConnection: boolean;
    }
  >;
  mailsInitLoading: boolean;
}

const initialState: InboxState = {
  mailsChains: [],
  mailsChainsLoading: true,
  nextMailsChains: null,

  mails: {},
  mailsInitLoading: true,

  mailsFilters: initMailsFilters(),
};

const updateMailsChainWithNewMailMapper =
  (newMail: Mail, contactId: string, userId: string) =>
  (mailsChain: MailsChain) => {
    const isDialogWithProperContact =
      mailsChain.contact.ulid_id === contactId &&
      mailsChain.user.ulid_id === userId;

    if (!isDialogWithProperContact) {
      return mailsChain;
    }

    return {
      ...mailsChain,
      body: newMail.body,
      status: newMail.status,
      sent_at: newMail.sent_at,
      is_incoming: newMail.is_incoming,
      unread_count: newMail.is_incoming ? mailsChain.unread_count + 1 : 0,
    };
  };

const setMailRead = () => (message: Mail) => {
  if (message.is_incoming || message.status === MailStatus.Failed) {
    return message;
  }

  return {
    ...message,
    status: MailStatus.Read,
  };
};

const mailsSlice = createSlice({
  name: 'mails',
  initialState,
  reducers: {
    updateMailsChatContactLimits(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        limits: Partial<ChatLimits>;
      }>
    ) {
      const { userId, contactId, limits } = action.payload;

      if (state.mails[`${contactId}-${userId}`]) {
        state.mails[`${contactId}-${userId}`].limits = {
          ...state.mails[`${contactId}-${userId}`].limits,
          ...limits,
        };
      }
    },

    setMailsFilters(state, action: PayloadAction<MailsFilters>) {
      state.mailsFilters = action.payload;

      setLocalStorageItem(
        MAILS_FILTERS_STORAGE_KEY,
        JSON.stringify(action.payload)
      );
    },

    // ? ***************** MAILS  ACTIONS START *****************
    setMailsChains(state, action: PayloadAction<MailsChain[]>) {
      state.mailsChains = action.payload;
    },

    setMailsChainsLoading(state, action: PayloadAction<boolean>) {
      state.mailsChainsLoading = action.payload;
    },

    setNextMailsChains(state, action: PayloadAction<string | null>) {
      state.nextMailsChains = action.payload;
    },

    addMailsChains(state, action: PayloadAction<MailsChain[]>) {
      state.mailsChains = [...action.payload, ...state.mailsChains];
    },

    updateMailsChainWithNewMail(
      state,
      action: PayloadAction<{
        message: Mail;
        userId: string;
        contactId: string;
      }>
    ) {
      const { message: newMail, userId, contactId } = action.payload;

      const isExistInMailsChain = Boolean(
        state.mailsChains.find(
          (mailsChain) => mailsChain.contact.ulid_id === contactId
        )
      );

      if (isExistInMailsChain) {
        state.mailsChains = state.mailsChains.map(
          updateMailsChainWithNewMailMapper(newMail, contactId, userId)
        );
      }
    },

    // ? ***************** MAILS CHAINS ACTIONS *****************

    // ? ***************** MAILS ACTIONS *****************
    setMails(
      state,
      action: PayloadAction<{
        isLiked: boolean;
        isEnabledEroticMedia: boolean;
        isBlocked: boolean;
        user: UserContact;
        contact: UserContact;
        next: string | null;
        limits: ChatLimits;
        messages: Mail[];
        isConnection: boolean;
      }>
    ) {
      const { user, contact } = action.payload;

      state.mails[`${contact.ulid_id}-${user.ulid_id}`] = action.payload;
    },

    setMailsInitLoading(state, action: PayloadAction<boolean>) {
      state.mailsInitLoading = action.payload;
    },

    addMail(
      state,
      action: PayloadAction<{
        message: Mail;
        userId: string;
        contactId: string;
      }>
    ) {
      const { message, userId, contactId } = action.payload;

      if (
        state.mails[`${contactId}-${userId}`] &&
        state.mails[`${contactId}-${userId}`].messages
      ) {
        let isMailAlreadyExist = false;

        const newMails = state.mails[`${contactId}-${userId}`].messages.map(
          (messageItem) => {
            if (messageItem.id === message.id) {
              isMailAlreadyExist = true;

              return message;
            }
            return messageItem;
          }
        );

        state.mails[`${contactId}-${userId}`].messages = isMailAlreadyExist
          ? newMails
          : [...newMails, message];
      } else {
        state.mails[`${contactId}-${userId}`] = {
          ...state.mails[`${contactId}-${userId}`],
          messages: [message],
          next: null,
        };
      }
    },

    updateMail(
      state,
      action: PayloadAction<{
        message: Mail;
        userId: string;
        contactId: string;
      }>
    ) {
      const { message, userId, contactId } = action.payload;

      if (
        state.mails[`${contactId}-${userId}`] &&
        state.mails[`${contactId}-${userId}`].messages
      ) {
        state.mails[`${contactId}-${userId}`].messages = state.mails[
          `${contactId}-${userId}`
        ].messages.map((oldMail) => {
          return oldMail.id === message.id ? message : oldMail;
        });
      } else {
        state.mails[`${contactId}-${userId}`] = {
          ...state.mails[`${contactId}-${userId}`],
          messages: [message],
          next: null,
        };
      }
    },

    addMails(
      state,
      action: PayloadAction<{
        messages: Mail[];
        userId: string;
        contactId: string;
        next: string | null;
      }>
    ) {
      const { messages, userId, contactId, next } = action.payload;

      const allMails =
        state.mails[`${contactId}-${userId}`].messages.concat(messages);

      state.mails[`${contactId}-${userId}`] = {
        ...state.mails[`${contactId}-${userId}`],
        messages: allMails,
        next,
      };
    },

    markMailsAsRead(
      state,
      action: PayloadAction<{ userId: string; contactId: string }>
    ) {
      const { userId, contactId } = action.payload;

      if (
        !state.mails[`${contactId}-${userId}`] ||
        !state.mails[`${contactId}-${userId}`].messages
      ) {
        return;
      }

      state.mails[`${contactId}-${userId}`].messages = state.mails[
        `${contactId}-${userId}`
      ].messages.map(setMailRead());
    },

    markMailsChainAsBlocked(
      state,
      action: PayloadAction<{ userId: string; contactId: string }>
    ) {
      const { userId, contactId } = action.payload;

      if (state.mails[`${contactId}-${userId}`])
        state.mails[`${contactId}-${userId}`].isBlocked = true;
    },

    markMailsChainAsConnection(
      state,
      action: PayloadAction<{ userId: string; contactId: string }>
    ) {
      const { userId, contactId } = action.payload;

      const isExistInMailsChains = Boolean(
        state.mailsChains.find((mailsChainItem) => {
          return (
            mailsChainItem.contact?.ulid_id === contactId &&
            mailsChainItem.user?.ulid_id === userId
          );
        })
      );

      if (state.mails[`${contactId}-${userId}`])
        state.mails[`${contactId}-${userId}`].isConnection = true;

      if (isExistInMailsChains)
        state.mailsChains = state.mailsChains.map((mailsChainItem) => {
          if (
            mailsChainItem.contact?.ulid_id === contactId &&
            mailsChainItem.user?.ulid_id === userId
          ) {
            return {
              ...mailsChainItem,
              connection_status: true,
            };
          }

          return mailsChainItem;
        });
    },

    // ? ***************** MAILS ACTIONS *****************

    // ? ***************** CONTACT ACTIONS *****************
    likeMailsChainContact(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        isLiked: boolean;
      }>
    ) {
      const { userId, contactId, isLiked } = action.payload;

      if (state.mails[`${contactId}-${userId}`])
        state.mails[`${contactId}-${userId}`].isLiked = isLiked;
    },
    // ? ***************** CONTACT ACTIONS *****************

    resetState() {
      return initialState;
    },
  },
});

export const {
  updateMailsChatContactLimits,

  setMailsChains,
  setMailsChainsLoading,
  addMailsChains,
  setNextMailsChains,
  setMailsFilters,

  setMails,
  setMailsInitLoading,
  addMail,

  updateMail,
  updateMailsChainWithNewMail,
  addMails,
  markMailsAsRead,
  markMailsChainAsBlocked,
  markMailsChainAsConnection,

  likeMailsChainContact,

  resetState,
} = mailsSlice.actions;

export const inbox = mailsSlice.reducer;
