import { MirrorService } from 'services/MirrorService';

import { ReactComponent as ChatsHouseDarkLogo } from 'assets/logos/chatshouse-dark.svg';
import { ReactComponent as ChatsHouseLightLogo } from 'assets/logos/chatshouse-light.svg';

export const getCurrentLogo = ({ isDark }: { isDark: boolean }) => {
  if (MirrorService.isChatsHouse) {
    return isDark ? ChatsHouseDarkLogo : ChatsHouseLightLogo;
  }

  return null;
};
