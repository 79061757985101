import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { TrackingApi } from 'api/TrackingApi';

import { useUserProfileUlid } from './useUserProfileUlid';

const ULID_LENGTH = 26;

export const usePageLoad = (isAuthenticated: boolean) => {
  const { pathname, search } = useLocation();
  const { userProfileUlid } = useUserProfileUlid();

  const handleTrackPageLoad = useCallback(() => {
    if (!isAuthenticated || !userProfileUlid) return;

    const contactId = pathname
      .split('/')
      .find((pathItem) => pathItem.length === ULID_LENGTH);

    TrackingApi.trackPageLoad({
      url: window.location.origin + pathname + search,
      currentUserId: userProfileUlid,
      interactionWithUserId: contactId,
    }).catch((err) => err);
  }, [isAuthenticated, pathname, search, userProfileUlid]);

  useEffect(() => {
    window.pageLoadId = uuidv4();
  }, [pathname]);

  useEffect(() => {
    handleTrackPageLoad();
  }, [handleTrackPageLoad]);
};
